import createHash from 'crypto-js/sha256';
import createHmac from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { parseContentType } from './utils';

export interface Credentials {
  algorithm: 'sha1' | 'sha256';
  id: string;
  key: string;
}

export interface Artifacts {
  app?: string;
  dlg?: string;
  ext?: string;
  hash?: string;
  host: string;
  method: string;
  nonce: string;
  port: number;
  resource: string;
  ts: string;
}

// Supported HMAC algorithms
export const algorithms = ['sha1', 'sha256'];

// MAC normalization format version
export const headerVersion = '1'; // Prevent comparison of mac values generated with different normalized string formats

export const generateNormalizedString = function (
  type: string,
  options: Artifacts
) {
  let resource = options.resource || '';
  if (resource && resource[0] !== '/') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const url = require('url').parse(resource, false);
    resource = url.path || ''; // Includes query
  }

  let normalized =
    'hawk.' +
    headerVersion +
    '.' +
    type +
    '\n' +
    options.ts +
    '\n' +
    options.nonce +
    '\n' +
    (options.method || '').toUpperCase() +
    '\n' +
    resource +
    '\n' +
    options.host.toLowerCase() +
    '\n' +
    options.port +
    '\n' +
    (options.hash || '') +
    '\n';

  if (options.ext) {
    normalized =
      normalized + options.ext.replace(/\\/g, '\\\\').replace(/\n/g, '\\n');
  }

  normalized = normalized + '\n';

  if (options.app) {
    normalized = normalized + options.app + '\n' + (options.dlg || '') + '\n';
  }

  return normalized;
};

// Calculate the request MAC

/**
 * ```
    type: 'header',                                 // 'header', 'bewit', 'response'
    credentials: {
        key: 'aoijedoaijsdlaksjdl',
        algorithm: 'sha256'                         // 'sha1', 'sha256'
    },
    options: {
        method: 'GET',
        resource: '/resource?a=1&b=2',
        host: 'example.com',
        port: 8080,
        ts: 1357718381034,
        nonce: 'd3d345f',
        hash: 'U4MKKSmiVxk37JCCrAVIjV/OhB3y+NdwoCr6RShbVkE=',
        ext: 'app-specific-data',
        app: 'hf48hd83qwkj',                        // Application id (Oz)
        dlg: 'd8djwekds9cj'                         // Delegated by application id (Oz), requires options.app
    }
  ```
*/
export const calculateMac = function (
  type: 'header' | 'bewit' | 'response',
  credentials: Credentials,
  options: Artifacts
) {
  const normalized = generateNormalizedString(type, options);
  const hmac = createHmac(normalized, credentials.key);
  const digest = Base64.stringify(hmac);
  return digest;
};

export const initializePayloadHash = function (contentType: string) {
  let content = 'hawk.' + headerVersion + '.payload\n';
  content += parseContentType(contentType) + '\n';
  return content;
};

export const calculatePayloadHash = function (
  payload: string,
  contentType: string
) {
  let hashContent = initializePayloadHash(contentType);
  hashContent += payload;
  hashContent += '\n';
  const hash = createHash(hashContent);
  return Base64.stringify(hash);
};
