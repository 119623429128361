/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray, isObject, camelCase, reduce, snakeCase } from 'lodash-es';

export function onEachObjectProp(
  obj: object,
  cb: (k: string) => string,
  skipPropsUnder: string[] = [],
  skipRecursively = true,
  skipCurrentProp?: boolean
): object {
  if (!isObject(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((v) =>
      onEachObjectProp(v, cb, skipPropsUnder, skipRecursively)
    );
  }
  return reduce(
    obj,
    (r, v, k) => {
      const skipUnder = skipPropsUnder.includes(k);

      if (skipUnder && skipRecursively) {
        return {
          ...r,
          [cb(k)]: v,
        };
      }

      return {
        ...r,
        [skipCurrentProp ? k : cb(k)]: onEachObjectProp(
          v,
          cb,
          skipPropsUnder,
          skipRecursively,
          skipUnder
        ),
      };
    },
    {}
  );
}

export function convertToCamelCase(
  obj: any,
  skipPropsUnder?: any[],
  skipRecursively?: boolean
): any {
  return onEachObjectProp(obj, camelCase, skipPropsUnder, skipRecursively);
}

export function convertToSnakeCase(
  obj: any,
  skipPropsUnder?: any[],
  skipRecursively?: boolean
): any {
  return onEachObjectProp(obj, snakeCase, skipPropsUnder, skipRecursively);
}
