import { ICountriesData, asyncDelay } from '../index';

/**
 * passing '91' returns 'India [+91]'
 * returns null if country code not valid
 *
 * @param callingCode phone country code
 */
export const getLabelForCallingCode = (
  callingCode: string,
  callingCodeChoices: ICountriesData['callingCodeChoices']
) => {
  const prefixName = callingCodeChoices.find((code) =>
    code[1].includes(`[+${callingCode}]`)
  );
  return prefixName ? prefixName[1] : null;
};

/**
 * separates calling code prefix and phone number (assuming space is present between them)
 * passing '+91 9876543210' will return  ['91', '9876543210']
 */
export const separateCallingCodeAndPhone = (phoneNumber: string) => {
  let callingCode = phoneNumber.substr(0, phoneNumber.indexOf(' '));
  const phone = phoneNumber.substr(phoneNumber.indexOf(' ') + 1);

  if (callingCode.startsWith('+')) {
    callingCode = callingCode.substr(1);
  }

  return [callingCode, phone];
};

/**
 * passing 'India [+91]' will return '91'
 */
export function getCallingCodeFromLabel(prefixValue: string) {
  const prefixCodeMatch = prefixValue.match(/\[\+(\d+)\]/);
  return prefixCodeMatch ? prefixCodeMatch[1] : '';
}

/** +91 8599992031 will be converted to +91 85*****31 */
export function getMaskedMobileNumber(mobileNumber = '') {
  const [prefix, phone] = separateCallingCodeAndPhone(mobileNumber);
  return `+${prefix} ${phone.substr(0, 2)}*****${phone.substr(-2)}`;
}

/**
 * used to prevent initial focus of input in a modal/popup
 */
export async function preventInputFocus(
  isOpen: boolean,
  /**
   * React ref for the container element inside which input is
   */
  containerRef: React.RefObject<HTMLDivElement>,
  /**
   * this query will be run on containerElement to find the inputElement
   */
  inputQuery: string
) {
  if (!inputQuery) {
    throw new Error('className param is required!');
  }

  await asyncDelay(); // deprioritize to assure dom element is mounted

  const inputElement =
    containerRef.current &&
    containerRef.current.querySelector<HTMLDivElement>(inputQuery);

  if (!inputElement) {
    return;
  }

  if (isOpen) {
    await asyncDelay();
    inputElement.style.visibility = 'visible';
  } else {
    // hide it to prevent focus when clicked next
    inputElement.style.visibility = 'hidden';
  }
}
