// Parse Content-Type header content
export const parseContentType = function (header?: string) {
  if (!header) {
    return '';
  }

  return header.split(';')[0].trim().toLowerCase();
};

const _now = Date.now;

export const now = function (localtimeOffsetMsec?: number) {
  return _now() + (localtimeOffsetMsec || 0);
};

export const nowSecs = function (localtimeOffsetMsec?: number) {
  return Math.floor(now(localtimeOffsetMsec) / 1000);
};
