/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from 'react';
import { Input, Select, message } from 'antd';
import styles from './phone-number.module.scss';
import {
  ICountryDetails,
  getCallingCodeFromLabel,
  getCountriesData,
  getLabelForCallingCode,
  separateCallingCodeAndPhone,
} from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';

interface IPhoneNumberProps {
  value?: string;
  onChange: (value: string) => void;
  defaultPrefixLabel?: string;
  placeholder?: string;
  showOnlyAllowedCountryCodes?: boolean;
  disabled?: boolean;
  onBlur?: (value: string) => void;
  suffix?: React.ReactNode;
  id?: string;
  disablePrefixPhoneSelect?: boolean;
}

const getLabelWithFlag = (flagURL: string, label = '') => {
  return (
    <span className={styles.LabelWithFlag}>
      <span>
        <img src={flagURL} alt="" />
      </span>
      <span>{label}</span>
    </span>
  );
};

export const PhoneNumber = ({
  defaultPrefixLabel = '91',
  placeholder = '',
  onChange,
  value = '',
  showOnlyAllowedCountryCodes = false,
  disabled = false,
  onBlur,
  suffix,
  id,
  disablePrefixPhoneSelect = false,
}: IPhoneNumberProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ callingCodes, countriesByCallingCodes }, setCountriesData] =
    useState<{
      callingCodes: [string, string][];
      countriesByCallingCodes: { [key: string]: ICountryDetails };
    }>({
      callingCodes: [],
      countriesByCallingCodes: {},
    });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [prefixLabel, phoneValue] = useMemo(() => {
    const [prefix, phone] = separateCallingCodeAndPhone(value || '');
    return [
      getLabelForCallingCode(prefix, callingCodes) ||
        getLabelForCallingCode(defaultPrefixLabel, callingCodes) ||
        '',
      phone,
    ];
  }, [value, callingCodes, defaultPrefixLabel]);

  const handlePrefixChange = (prefix: string) => {
    onChange(`+${getCallingCodeFromLabel(prefix)} ${phoneValue}`);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (/\D/g.test(val)) {
      // do not accept if contains non-numeric value
      return;
    }

    onChange(`+${getCallingCodeFromLabel(prefixLabel)} ${e.target.value}`);
  };

  useEffect(() => {
    async function fetchCountriesData() {
      setIsLoading(true);

      try {
        const data = await getCountriesData();
        setCountriesData({
          callingCodes: showOnlyAllowedCountryCodes
            ? data.callingCodeChoices.filter(([prefix]) =>
                data.allowedCallingCodes.includes(prefix)
              )
            : data.callingCodeChoices,
          countriesByCallingCodes: data.countriesByCallingCodes,
        });
      } catch (error) {
        message.error((error as IErrorResponse).message);
      }

      setIsLoading(false);
    }

    fetchCountriesData();
  }, [showOnlyAllowedCountryCodes]);

  const phoneLengthRange = (
    countriesByCallingCodes[getCallingCodeFromLabel(prefixLabel)] || {}
  ).mobileNumberNsnDigits;

  const hint = phoneLengthRange
    ? `${phoneLengthRange.min}${
        phoneLengthRange.max !== phoneLengthRange.min
          ? ` to ${phoneLengthRange.max}`
          : ''
      } digit mobile number`
    : 'mobile no. without prefix';

  return (
    <Input.Group className={styles.PhoneNumber} compact>
      <Select
        loading={isLoading}
        disabled={isLoading || disabled || disablePrefixPhoneSelect}
        className={styles.Dropdown}
        optionFilterProp="title"
        placeholder="Prefix"
        showSearch
        value={prefixLabel}
        onChange={handlePrefixChange}
        size="large"
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={(change) => setIsDropdownOpen(change)}
      >
        {(callingCodes || []).map(([key, label]) => {
          const flagURL = (countriesByCallingCodes[key] || {}).flag || '';
          return (
            <Select.Option key={label} title={label} value={label}>
              {isDropdownOpen
                ? getLabelWithFlag(flagURL, label)
                : getLabelWithFlag(flagURL, `+${key}`)}
            </Select.Option>
          );
        })}
      </Select>
      <Input
        id={id}
        type="tel"
        className={styles.Text}
        disabled={isLoading || disabled}
        size="large"
        value={phoneValue}
        onChange={handleNumberChange}
        placeholder={placeholder || hint}
        autoComplete="tel-national"
        onBlur={
          onBlur &&
          (() =>
            onBlur(`+${getCallingCodeFromLabel(prefixLabel)} ${phoneValue}`))
        }
        suffix={suffix}
      />
    </Input.Group>
  );
};
