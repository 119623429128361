/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import { stringify } from 'query-string';

import { Auth } from '../utils/auth';
import { asyncStorage } from './async-storage';
import { IAsyncStorage, IAuth } from '../typings';
import { amsDevicePingPath, API_BASE_URL, MAX_TIMEOUT_MS } from './constants';
import {
  convertToCamelCase,
  createDeviceAuthHeader,
  createPath,
  getDeviceCredentials,
  getErrorMessage,
  removeDeviceCredentials,
  removeDeviceIdFromStorage,
} from '../utils';
import { refreshAccessTokenIfNeeded } from './auth';
import { isValidURL } from './common';
interface Config {
  apiBaseURL?: string;
  siteVersion?: string;
  siteId?: string;
  onLogout?: (message?: 'MAX_AUTH_ERROR_THRESHOLD_REACHED') => void;
  storage?: IAsyncStorage;
  deviceId?: string | (() => Promise<string> | 'UNKNOWN') | (() => string);
  deviceIdGenerator: () => Promise<string>;
}

class Instance {
  public client: AxiosInstance;
  public storage: IAsyncStorage;
  public auth: IAuth;
  public config: Required<Pick<Config, 'onLogout' | 'deviceIdGenerator'>>;

  public constructor() {
    this.client = axios.create({
      baseURL: API_BASE_URL,
      paramsSerializer(params: any): any {
        return stringify(params);
      },
    });
    this.storage = asyncStorage;
    this.auth = new Auth(this.storage);
    this.config = {
      onLogout: async () => {
        await this.auth.removeAccessToken();
        await this.auth.removeRefreshToken();
        await removeDeviceCredentials();
        await removeDeviceIdFromStorage();
      },
      deviceIdGenerator: async () => '',
    };
  }

  public setStorage(storage: IAsyncStorage) {
    this.storage = storage;
    this.auth = new Auth(this.storage);
  }
}

export const instance = new Instance();

let refreshTokenTimeouReference: number | null = null;
export const startAccessTokenRefreshProcess = async (
  force = false,
  graceMinutes = 1
) => {
  // clear any previous timeout
  if (refreshTokenTimeouReference) {
    window.clearTimeout(refreshTokenTimeouReference);
    refreshTokenTimeouReference = null;
  }

  const { expiresInMS, graceMs } = await refreshAccessTokenIfNeeded(
    force,
    graceMinutes
  );

  // set timeout to refresh token half a minute before it's expiry
  let timeoutDelay = Math.max(expiresInMS - graceMs, 30000); // timeout interval not less than 30 seconds
  if (timeoutDelay > MAX_TIMEOUT_MS) {
    timeoutDelay = MAX_TIMEOUT_MS;
  }

  refreshTokenTimeouReference = window.setTimeout(
    () =>
      startAccessTokenRefreshProcess(true).catch((e) => {
        console.error(e);
      }),
    timeoutDelay
  );
};

// axios middleware
instance.client.interceptors.request.use(
  // @ts-ignore
  async (
    requestConfig: AxiosRequestConfig & { noAuth?: boolean }
  ): Promise<AxiosRequestConfig> => {
    const updatedConfig = requestConfig;
    try {
      const token = await instance.auth.getAccessToken();
      if (token && !updatedConfig.noAuth) {
        // @ts-ignore
        updatedConfig.headers.Authorization = `bearer ${token}`;
      }
    } catch (error) {
      console.error({ error });
    }

    const queryString = stringify(requestConfig.params) || '';

    const url = `${requestConfig.baseURL}${requestConfig.url}${
      queryString && `?${queryString}`
    }`;

    try {
      const deviceCreds = await getDeviceCredentials();
      if (deviceCreds && !updatedConfig.noAuth) {
        const authHeader = await createDeviceAuthHeader({
          url,
          method: requestConfig.method?.toUpperCase() as 'GET',
          headers: requestConfig.headers,
          data: requestConfig.data,
        });
        // @ts-ignore
        updatedConfig.headers.Authorization = authHeader;
      }
    } catch (error) {
      console.error({ error });
    }

    try {
      const deviceId = await instance.config.deviceIdGenerator();
      if (deviceId) {
        // @ts-ignore
        updatedConfig.headers['x-device-id'] = deviceId;
      }
    } catch (error) {
      console.error({ error });
    }

    return {
      ...updatedConfig,
    };
  },
  (error: any): Promise<AxiosError> => Promise.reject(error)
);

const MAX_AUTH_ERROR_COUNT = 4;
let authErrorCount = 0;
instance.client.interceptors.response.use(
  (res: any): any => {
    // reset auth error count if 401 is not received consecutively
    authErrorCount = 0;
    const data = res.data.data || res.data;
    return res.config.convertResponseToCamelCase !== false
      ? convertToCamelCase(data)
      : data;
  },
  async (error: any): Promise<any> => {
    console.error(error);
    const response = error.response || {};
    const { data = {} } = response;
    let errorMessage = 'Something went wrong. Please try again later.';
    // few APIs which do not require authentication also send 401
    // for eg. forgot-password sends 401 when email is unverified
    switch (response.status) {
      case undefined:
        if (!navigator.onLine) {
          errorMessage = 'No network connection!';
        }
        break;
      case 502:
      case 500:
      case 501:
        break;
      case 401:
        if ((await instance.auth.getAccessToken()) !== null) {
          try {
            await startAccessTokenRefreshProcess(true);

            if (!error.config) {
              return;
            }

            // resend the request with same config but new token, if token refresh successful
            const newConfig = {
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization:
                  'bearer ' + (await instance.auth.getAccessToken()),
              },
            };
            return await instance.client.request(newConfig);
          } catch (err) {
            await instance.config.onLogout();
            errorMessage = 'Your session has expired please login again.';
          }
        } else {
          // for ams mobile app
          // only logout if received 401 consecutively MAX_AUTH_ERROR_COUNT times
          // otherwise just ignore 401
          console.error('received 401 in mobile app');
          const isPingApi = response.request?.responseURL?.match(
            new RegExp(createPath(amsDevicePingPath, { deviceId: '.+' }))
          );
          if (isPingApi && authErrorCount < MAX_AUTH_ERROR_COUNT) {
            authErrorCount++;
            break;
          }
          authErrorCount = 0;
          console.error('MAX_AUTH_ERROR_THRESHOLD_REACHED');
          await instance.config.onLogout(
            isPingApi ? 'MAX_AUTH_ERROR_THRESHOLD_REACHED' : undefined
          );
          errorMessage = getErrorMessage(
            data,
            errorMessage || 'Your session has expired please login again.'
          );
        }
        break;
      case 403:
        getErrorMessage(
          data,
          errorMessage || 'You do not have permission to perform this action.'
        );
        break;
      case 404:
        errorMessage = 'Requested resource not found.';
        break;
      default:
        errorMessage = getErrorMessage(data, errorMessage);
    }

    // reset auth error count if 401 is not received consecutively
    if (response.status !== 401) {
      authErrorCount = 0;
    }
    const exception = new Error(errorMessage);
    if (data && data.error && data.error.message) {
      // @ts-ignore
      exception.fields = data.error.message;
    }

    if (data?.error?.message?.display_text) {
      // @ts-ignore
      exception.info = data.error.message;
    }
    // @ts-ignore
    exception.response = response;
    return Promise.reject(exception);
  }
);

export const configureSiteVersion = (siteVersion: string) => {
  // @ts-ignore
  instance.client.defaults.headers = {
    ...instance.client.defaults.headers,
    // @ts-ignore
    'x-site-version': siteVersion,
  };
};

export const configureOneSignalSubscriptionId = (subscriptionId: string) => {
  // @ts-ignore
  instance.client.defaults.headers = {
    ...instance.client.defaults.headers,
    // @ts-ignore
    'x-onesignal-subscription-id': subscriptionId,
  };
};

export const removeOneSignalSubscriptionId = () => {
  // @ts-ignore
  instance.client.defaults.headers = {
    ...instance.client.defaults.headers,
    // @ts-ignore
    'x-onesignal-subscription-id': undefined,
  };
};

export const configure = ({
  apiBaseURL = '',
  storage = asyncStorage,
  onLogout,
  siteVersion = 'UNKNOWN',
  siteId = 'default',
  deviceIdGenerator,
}: Omit<Config, 'deviceId'>): void => {
  if (isValidURL(apiBaseURL)) {
    instance.client.defaults.baseURL = apiBaseURL;
  }
  if (storage) {
    instance.setStorage(storage);
  }
  // @ts-ignore
  instance.client.defaults.headers = {
    // @ts-ignore
    'x-site-version': siteVersion,
    'x-site-id': siteId,
  };
  instance.config.onLogout = async (): Promise<void> => {
    await instance.auth.removeAccessToken();
    await instance.auth.removeRefreshToken();
    if (typeof onLogout === 'function') {
      await onLogout();
    }
  };
  instance.config.deviceIdGenerator =
    deviceIdGenerator || (async () => 'UNKNOWN');
};

export const configureLogout = ({ onLogout }: Pick<Config, 'onLogout'>) => {
  instance.config.onLogout = async (): Promise<void> => {
    await instance.auth.removeAccessToken();
    await instance.auth.removeRefreshToken();
    if (typeof onLogout === 'function') {
      await onLogout();
    }
  };
};

export const configureDevice = async ({
  apiBaseURL = '',
  storage = asyncStorage,
  siteId,
  siteVersion,
  onLogout,
  deviceId,
}: Pick<
  Config,
  'deviceId' | 'apiBaseURL' | 'storage' | 'onLogout' | 'siteId' | 'siteVersion'
>) => {
  if (isValidURL(apiBaseURL)) {
    instance.client.defaults.baseURL = apiBaseURL;
  }

  if (storage) {
    instance.setStorage(storage);
  }

  // @ts-ignore
  instance.client.defaults.headers = {
    // @ts-ignore
    'x-site-version': siteVersion,
    // @ts-ignore
    'x-site-id': siteId,
    // @ts-ignore
    'x-device-id': typeof deviceId === 'function' ? await deviceId() : deviceId,
  };

  instance.config.onLogout = async (message): Promise<void> => {
    await removeDeviceCredentials();
    await removeDeviceIdFromStorage();
    if (typeof onLogout === 'function') {
      await onLogout(message);
    }
  };
};
