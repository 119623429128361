/* eslint-disable @typescript-eslint/ban-ts-comment */
// use single AudioContext class, as some browsers have a limit
// eslint-disable-next-line

import { asyncDelay } from '@datapeace/1up-frontend-shared-api';

// @ts-ignore
const audioContext = new (window.AudioContext || window.webkitAudioContext)();

export interface BeepParams {
  duration?: number;
  frequency?: number;
  volume?: number;
  type?: OscillatorType;
}

export function beep(options?: BeepParams) {
  const {
    duration = 1000,
    frequency = 1000,
    volume = 10,
    type = 'sawtooth',
  } = options || {};

  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  gainNode.gain.value = volume;
  oscillator.frequency.value = frequency;
  oscillator.type = type;

  oscillator.start(audioContext.currentTime);
  oscillator.stop(audioContext.currentTime + (duration || 500) / 1000);

  return new Promise<void>((resolve) => {
    oscillator.onended = () => resolve();
  });
}

export const playAudio = (src: string) => new Audio(src).play();

export const successBeep = async (
  audioSrc?: string,
  volume?: BeepParams['volume']
) => {
  if (audioSrc) return playAudio(audioSrc);
  return beep({ duration: 1500, volume });
};

export const errorBeep = async (
  audioSrc?: string,
  volume?: BeepParams['volume'],
  repeat = 4
) => {
  if (audioSrc) {
    await playAudio(audioSrc);
    return;
  }

  const errorBeepOptions: BeepParams = {
    volume,
    duration: 400,
    frequency: 400,
  };

  // initial beep
  await beep(errorBeepOptions);

  /* eslint-disable no-await-in-loop */
  for (let i = 1; i < repeat; i += 1) {
    await asyncDelay(25);
    await beep(errorBeepOptions);
  }
  /* eslint-enable no-await-in-loop */
};
