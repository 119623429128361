/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomLoginPageItem, SigninResponse } from '../typings';
import {
  convertToSnakeCase,
  createPath,
  validateEmail,
  validatePassword,
  validatePhone,
} from '../utils';
import { instance, startAccessTokenRefreshProcess } from './client';
import JwtDecode from 'jwt-decode';
import {
  API_BASE_URL,
  apiCustomLoginPageList,
  apiDeleteUserPath,
  apiEmailVerify,
  apiForgotPassword,
  apiLoginPath,
  apiLogoutPath,
  apiRefreshAccessTokenPath,
  apiResendLoginOTPPath,
  apiResendVerificationMail,
  apiSendLoginOTPPath,
  apiSignupPath,
  apiSsoLoginPath,
} from './constants';
import { AxiosResponse } from 'axios';

export const refreshAccessToken = async () => {
  const storedAccessToken = await instance.auth.getAccessToken();
  const storedRefreshToken = await instance.auth.getRefreshToken();

  if (!storedAccessToken || !storedRefreshToken) {
    throw new Error('You are not authenticated! Please login.');
  }

  const res = await instance.client.post<SigninResponse>(
    createPath(apiRefreshAccessTokenPath),
    convertToSnakeCase({
      accessToken: storedAccessToken,
      refreshToken: storedRefreshToken,
    })
  );

  await instance.auth.setAccessToken(res.accessToken);
  await instance.auth.setRefreshToken(res.refreshToken);

  return res;
};

export const refreshAccessTokenIfNeeded = async (
  force = false,
  graceMinutes = 1
) => {
  console.info('Checking if access token refresh needed!');

  const storedAccessToken = await instance.auth.getAccessToken();

  if (!storedAccessToken) {
    console.error('No stored access token found, not refreshing');
    throw new Error('You are not authenticated! Please login.');
  }

  const { exp } = JwtDecode<{ exp: number }>(storedAccessToken);
  let expiresInMS = +new Date(exp * 1000) - Date.now();

  const graceMs = graceMinutes * 60 * 1000;

  // if token expires within a minute, refresh it
  if (expiresInMS <= graceMs || force) {
    try {
      console.log('Refreshing access token!');
      const { accessTokenExpiresIn } = await refreshAccessToken();
      expiresInMS = accessTokenExpiresIn * 1000;
    } catch (err: any) {
      if (err && err.response && err.response.status) {
        await instance.config.onLogout();
      }
      throw err;
    }
  }

  return { expiresInMS, graceMs };
};

export async function logout() {
  const storedAccessToken = await instance.auth.getAccessToken();
  const storedRefreshToken = await instance.auth.getRefreshToken();
  if (!storedAccessToken && !storedRefreshToken) {
    return;
  }
  await instance.client.post(
    createPath(apiLogoutPath),
    {
      access_token: storedAccessToken,
      refresh_token: storedRefreshToken || undefined,
    },
    {
      // @ts-ignore
      noAuth: true,
    }
  );
}

export const resendVerificationMail = async (
  email: string
): Promise<string> => {
  if (!validateEmail(email)) {
    throw new Error('Invalid email.');
  }
  await instance.client.post(createPath(apiResendVerificationMail), { email });
  return 'You will soon receive an email with further instructions.';
};

export const getCustomLoginPagesList = async (orgSlug: string) => {
  const res = await instance.client.get<
    null,
    {
      pages: CustomLoginPageItem[];
    }
  >(createPath(apiCustomLoginPageList, { orgSlug }), {
    // @ts-ignore
    noAuth: true,
  });
  return res.pages;
};

interface Signup {
  email: string;
  password: string;
  invitationCode: string;
}

interface EmailSigninRequest {
  email: string;
  password: string;
  remember: boolean;
}

interface MobileSigninRequest {
  mobile: string;
  otp: string;
  remember: boolean;
}

export const setTokens = async ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  await instance.auth.setAccessToken(accessToken);
  await instance.auth.setRefreshToken(refreshToken);
  startAccessTokenRefreshProcess().catch((error) => {
    console.error(error);
  });
};

export const signup = async ({
  email,
  password,
  invitationCode,
}: Signup): Promise<boolean> => {
  if (!validateEmail(email)) {
    throw new Error('Invalid email.');
  }
  if (!validatePassword(password)) {
    throw new Error('Invalid password.');
  }
  if (!invitationCode) {
    throw new Error('Please provide invitation code');
  }
  await instance.client.post(
    createPath(apiSignupPath),
    convertToSnakeCase({ email, password, invitationCode })
  );
  return true;
};

export const emailLogin = async ({
  email,
  password,
  remember,
}: EmailSigninRequest) => {
  if (!validateEmail(email)) {
    throw new Error('Invalid email');
  }

  if (!validatePassword(password)) {
    throw new Error('Invalid password.');
  }

  const res = await instance.client.post<SigninResponse>(
    createPath(apiLoginPath),
    convertToSnakeCase({ email, password, remember })
  );

  if (res) {
    const { accessToken, refreshToken } = res;
    await setTokens({ accessToken: accessToken, refreshToken });
    return true;
  }
  return false;
};

export const mobileLogin = async ({
  mobile,
  remember,
  otp,
}: MobileSigninRequest): Promise<boolean> => {
  if (!validatePhone(mobile)) {
    throw new Error('Invalid mobile number');
  }

  const res = await instance.client.post<SigninResponse>(
    createPath(apiLoginPath),
    convertToSnakeCase({ authType: 'mobile', mobile, otp, remember })
  );

  if (res) {
    const { accessToken, refreshToken } = res;
    await setTokens({ accessToken: accessToken, refreshToken });
    return true;
  }

  return false;
};

export const sendOTPForLogin = async (
  mobile: string
): Promise<AxiosResponse> => {
  if (mobile && !validatePhone(mobile)) {
    throw new Error('Invalid phone number.');
  }

  const res = await instance.client.post(createPath(apiSendLoginOTPPath), {
    mobile,
  });
  // @ts-ignore
  return res.message;
};

export const resendOTPForLogin = async (
  mobile: string
): Promise<AxiosResponse> => {
  if (mobile && !validatePhone(mobile)) {
    throw new Error('Invalid phone number.');
  }

  const res = await instance.client.post<{ message: string }>(
    createPath(apiResendLoginOTPPath),
    { mobile }
  );
  return res.message;
};

export const forgotPassword = async ({
  email,
}: {
  email: string;
}): Promise<string> => {
  if (!validateEmail(email)) {
    throw new Error('Invalid email.');
  }
  await instance.client.get(createPath(apiForgotPassword), {
    params: { email },
  });
  return "We have sent a reset password link to your email. Didn't receive the email? Check email address again or look in your spam folder.";
};

export const resetPassword = async (
  password: string,
  token: string
): Promise<string> => {
  if (!token) {
    throw new Error('Invalid token.');
  }
  if (!validatePassword(password)) {
    throw new Error('Invalid password.');
  }
  await instance.client.post(createPath(apiForgotPassword), {
    token,
    password,
  });
  return 'Your password has been set successfully.';
};

export const verifyEmailToken = async (token: string): Promise<string> => {
  if (!token) {
    throw new Error('Invalid or expired token.');
  }
  await instance.client.post(createPath(apiEmailVerify, { token }));
  return 'The email was verified successfully. You can now login and start using the application.';
};

export const deactivateCurrentUser = async (): Promise<AxiosResponse> =>
  instance.client.get(createPath(apiDeleteUserPath));

export const ssoLogin = async (orgSlug: string, shouldRedirect = true) => {
  try {
    await instance.client.get<{ loginUrl: string }>(
      createPath(apiSsoLoginPath, { orgSlug }),
      {
        params: {
          check_availability_only: true,
        },
      }
    );
    if (shouldRedirect) {
      window.location.replace(
        createPath([API_BASE_URL, apiSsoLoginPath], { orgSlug })
      );
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};
