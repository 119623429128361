export const CURRENT_ORG_KEY = (value: { user: string }) =>
  `${value.user}-currentOrganizationId`;
export const CURRENT_SPACE_KEY = (value: { user: string }) =>
  `${value.user}-currentSpaceId`;

export const RESTRICTED_MODE_KEY = () => `restricted_mode`;
export const RESTRICTED_VMS_MODE_KEY = () => `restricted_vms_mode`;
export const RESTRICTED_FACE_CAMERA_VALIDATION_METHOD_KEY = () =>
  `restricted_face_camera_validation_method`;

export const LOGGED_OUT_EVENT = 'LOGGED_OUT_EVENT';

const getItem = (key: string): string | null => localStorage.getItem(key);

const setItem = (key: string, value: string): void =>
  localStorage.setItem(key, value);

const removeItem = (key: string): void => localStorage.removeItem(key);

const clearAll = (): void => localStorage.clear();

export const storage = { getItem, setItem, removeItem, clearAll };

export function setCurrentSpaceInStorage(email: string, spaceId: number) {
  return storage.setItem(CURRENT_SPACE_KEY({ user: email }), String(spaceId));
}

export function getCurrentSpaceFromStorage(email: string) {
  return storage.getItem(CURRENT_SPACE_KEY({ user: email }));
}

export function removeCurrentSpaceFromStorage(email: string) {
  return storage.removeItem(CURRENT_SPACE_KEY({ user: email }));
}

export function setCurrentOrganizationInStorage(
  email: string,
  organizationId: number
) {
  return storage.setItem(
    CURRENT_ORG_KEY({ user: email }),
    String(organizationId)
  );
}

export function getCurrentOrganizationFromStorage(email: string) {
  return storage.getItem(CURRENT_ORG_KEY({ user: email }));
}

export function removeCurrentOrganizationFromStorage(email: string) {
  return storage.removeItem(CURRENT_ORG_KEY({ user: email }));
}

export function setRestrictedModeInStorage(value: string) {
  return storage.setItem(RESTRICTED_MODE_KEY(), String(value));
}

export function removeRestrictedModeFromStorage() {
  return storage.removeItem(RESTRICTED_MODE_KEY());
}

export function getRestrictedModeFromStorage() {
  return storage.getItem(RESTRICTED_MODE_KEY()) === '1';
}
