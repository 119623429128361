import { ICountriesData, ICountryDetails } from '../typings';
import { createPath } from '../utils';
import { instance } from './client';
import { apiCountriesDataPath } from './constants';

let countriesData: ICountriesData & {
  countriesByCallingCodes: { [key: string]: ICountryDetails };
};
export const getCountriesData = async () => {
  // returns from cache if exists, else fetches and saves to cache
  if (countriesData) {
    return countriesData;
  }

  const res = await instance.client.get<ICountriesData>(
    createPath(apiCountriesDataPath),
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      noAuth: true,
    }
  );
  countriesData = { ...(res as ICountriesData), countriesByCallingCodes: {} };

  // filter out any countries with no timezone data
  countriesData.countries = countriesData.countries.filter(
    ({ timezones }) => !!timezones[0].name
  );

  // adds countriesByCallingCode
  countriesData.countries.forEach((country) => {
    country.callingCodes.forEach((code) => {
      if (!code) return;
      countriesData.countriesByCallingCodes[code] = country;
    });
  });

  return countriesData;
};

export const isValidURL = (string = ''): boolean => string.startsWith('http');

export function downloadFileFromUrl(url: string, filename?: string): void {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('hidden', 'true');
  link.setAttribute('target', '_blank');
  link.setAttribute('download', filename || '');
  document.body.appendChild(link);
  link.click();
}

export const getPageTitle = (
  isOpen: boolean,
  suffix: string,
  data: any[] = []
): string => {
  return `${
    isOpen
      ? `${data.join(' ')} ${
          data.some((value): boolean => value) ? '-' : 'Create - '
        } `
      : ''
  }${suffix}`;
};
