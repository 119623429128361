import { useState } from 'react';
import { Button, Input, message, Form, Row, Alert } from 'antd';
import clsx from 'clsx';
import styles from '../input.module.scss';
import '../index.scss';
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  VaultAccessMobileOtpSend,
  VaultAccessMobileLogin,
  phoneValidator,
  VMS_APP_PATH,
} from '@datapeace/custom-web-utils';
import { ReactTitle } from 'react-meta-tags';
import { useCountdown } from '@datapeace/1up-frontend-web-utils';
import { createPath } from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import { PhoneNumber } from '@datapeace/custom-web-components';

export function VaultAccess() {
  const { orgIdSlug } = useParams();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [form] = Form.useForm<{ mobile: string; otp: string }>();

  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOTPSentSuccessfully, setIsOTPSentSuccessfully] = useState(false);
  const [resendOTPCountdown] = useCountdown(isOTPSentSuccessfully ? 30 : 0);

  const handleResetState = () => {
    setIsOTPSentSuccessfully(false);
    form?.resetFields();
  };

  const handleMobileNumberChange = () => {
    if (isOTPSentSuccessfully) {
      setIsOTPSentSuccessfully(false);
    }
  };

  const handleSignIn = async ({
    mobile,
    otp,
  }: {
    mobile: string;
    otp: string;
  }) => {
    const removedPrefixMobile = mobile.substring(3).trim();
    try {
      setIsFormSubmitting(true);
      const response = await VaultAccessMobileLogin({
        mobile: removedPrefixMobile,
        org_id_slug: orgIdSlug as string,
        otp,
      });
      const restrictedModeNumber = 1;
      window.location.replace(
        createPath([VMS_APP_PATH, 'settings']) +
          '?' +
          new URLSearchParams({
            token: response?.accessToken,
            org: response?.orgId,
            space: response?.spaceId,
            restricted_mode: restrictedModeNumber.toString(),
            logout_redirect: window.location.href,
            vms_mode: 'NORMAL',
          }).toString()
      );
    } catch (error) {
      message.error((error as IErrorResponse).message);
      setErrorMessage(
        (error as IErrorResponse).message ||
          'Something went wrong! Please try again.'
      );
      setIsFormSubmitting(false);
    }
  };

  const handleSendOTP = async ({ mobile }: { mobile: string }) => {
    setIsOTPSentSuccessfully(false);
    setIsSendingOTP(true);
    const removedPrefixMobile = mobile.substring(3).trim();
    try {
      await VaultAccessMobileOtpSend({
        mobile: removedPrefixMobile,
        org_id_slug: orgIdSlug as string,
      });
      setIsSendingOTP(false);
      setIsOTPSentSuccessfully(true);
      message.success(
        'OTP is sent to given mobile number if we found any related account.'
      );
    } catch (err) {
      setIsSendingOTP(false);
      setErrorMessage(
        (err as IErrorResponse).message ||
          'Something went wrong! Please try again.'
      );
    }
  };
  return (
    <Row justify="center" id="Container">
      <Form
        layout="vertical"
        form={form}
        id="Content"
        onFinish={isOTPSentSuccessfully ? handleSignIn : handleSendOTP}
      >
        <ReactTitle title="Soteria - Vault Access Login" />

        <div>
          <img src="/images/soteria-logo.png" alt="logo" height="56px" />
        </div>
        {<p id="Tagline">Login to access vault</p>}
        {errorMessage && (
          <Alert
            banner
            style={{
              color: 'red',
              fontSize: '1.1em',
              marginBottom: '1.5em',
            }}
            message={errorMessage}
            type="error"
            showIcon
          />
        )}
        <Form.Item
          name="mobile"
          id="Input"
          className={clsx(styles.Input, 'tel')}
          rules={[
            {
              validator: (rule, phone) => phoneValidator(phone),
              message: 'Please enter a valid mobile number!',
            },
          ]}
          validateTrigger="onChange"
          hasFeedback
        >
          <PhoneNumber
            placeholder="Enter mobile number"
            showOnlyAllowedCountryCodes={true}
            suffix={
              <CloseOutlined
                tabIndex={-1}
                id={!form.getFieldValue('mobile') ? 'ResetButton__Hidden' : ''}
                onClick={() => handleResetState()}
              />
            }
            disablePrefixPhoneSelect
            defaultPrefixLabel="91"
            onChange={handleMobileNumberChange}
          />
        </Form.Item>
        {isOTPSentSuccessfully && (
          <Form.Item
            className={clsx(styles.Input, 'number')}
            name="otp"
            rules={[{ required: true, message: 'Please enter OTP here!' }]}
            validateTrigger="onChange"
            hasFeedback
          >
            <Input
              placeholder="OTP"
              autoComplete="one-time-code"
              suffix={
                <Button
                  style={{
                    marginLeft: '10px',
                  }}
                  loading={isSendingOTP}
                  disabled={resendOTPCountdown !== 0 || isFormSubmitting}
                  htmlType="button"
                  ghost
                  size="small"
                  type="primary"
                  onClick={async () => {
                    try {
                      await form.validateFields(['mobile']);
                      handleSendOTP({
                        mobile: form?.getFieldValue('mobile'),
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Resend
                  {resendOTPCountdown ? ` (${resendOTPCountdown})` : ''}
                </Button>
              }
              inputMode="numeric"
            />
          </Form.Item>
        )}
        <Button
          loading={isFormSubmitting || isSendingOTP}
          disabled={isFormSubmitting || isSendingOTP}
          htmlType="submit"
          type="primary"
          id="Submit"
        >
          {isOTPSentSuccessfully ? 'Submit' : `Send OTP`}
        </Button>
      </Form>
    </Row>
  );
}
