import { useState } from 'react';
import jsQR from 'jsqr';
import { useAnimationFrame } from './useAnimationFrame';
import { objectFit } from '../lib';

const staticCanvas = document.createElement('canvas');
const staticCanvasContext = staticCanvas.getContext('2d');

export function useQrReader(
  inputVideo: HTMLVideoElement | null,
  outputCanvas: HTMLCanvasElement | null,
  shouldDrawBoundingBox = false,
  onQrDetected?: (qrText: string) => void
) {
  const [lastQrData, setLastQrData] = useState<{ text: string; time: number }>({
    text: '',
    time: 0,
  });

  const qrReaderLoop = () => {
    if (!inputVideo || !outputCanvas || !staticCanvasContext) return;

    const { videoWidth, videoHeight } = inputVideo;
    const { width: canvasWidth, height: canvasHeight } = outputCanvas;
    staticCanvas.width = canvasWidth;
    staticCanvas.height = canvasHeight;

    const { offsetX, offsetY, width, height } = objectFit(false)(
      canvasWidth,
      canvasHeight,
      videoWidth,
      videoHeight
    );

    staticCanvasContext.drawImage(inputVideo, offsetX, offsetY, width, height);
    const imageData = staticCanvasContext.getImageData(
      0,
      0,
      canvasWidth,
      canvasHeight
    );
    const scanResult = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert',
    });

    const ctx = outputCanvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    if (!scanResult || !scanResult.data.trim()) {
      // if (qrData) setQrData('');
      return;
    }

    // ignore if same as last scanned qr within 2 seconds
    if (
      !(
        lastQrData.text === scanResult.data &&
        Date.now() - lastQrData.time < 2000
      )
    ) {
      setLastQrData({ text: scanResult.data, time: Date.now() });
      if (onQrDetected) onQrDetected(scanResult.data);
    }

    const {
      topLeftCorner,
      topRightCorner,
      bottomRightCorner,
      bottomLeftCorner,
    } = scanResult.location;

    if (shouldDrawBoundingBox) {
      ctx.beginPath();

      ctx.lineTo(topLeftCorner.x, topLeftCorner.y);
      ctx.lineTo(topRightCorner.x, topRightCorner.y);
      ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y);
      ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y);
      ctx.lineTo(topLeftCorner.x, topLeftCorner.y);

      ctx.lineWidth = 2;
      ctx.strokeStyle = '#333';
      ctx.stroke();
    }
  };

  useAnimationFrame(
    inputVideo && outputCanvas
      ? () => {
          try {
            qrReaderLoop();
          } catch (err) {
            console.error(err);
          }
        }
      : null
  );

  return lastQrData.text;
}
