import { useEffect, useCallback } from 'react';
import jsQR from 'jsqr';
import { bytesToBase64 } from 'byte-base64';
import { objectFit } from '../lib';

const staticCanvas = document.createElement('canvas');
const staticCanvasContext = staticCanvas.getContext('2d');
const SCAN_PERIOD_MS = 100;

export function useVaccinationCertificateQRReader(
  inputVideo: HTMLVideoElement | null,
  outputCanvas: HTMLCanvasElement | null,
  onQrDetected: (inputBase64: string) => void,
  isVerifying: boolean
) {
  const qrReaderLoop = useCallback(() => {
    if (!inputVideo || !outputCanvas || !staticCanvasContext || isVerifying)
      return;

    const { videoWidth, videoHeight } = inputVideo;
    const { width: canvasWidth, height: canvasHeight } = outputCanvas;
    staticCanvas.width = canvasWidth;
    staticCanvas.height = canvasHeight;

    const { offsetX, offsetY, width, height } = objectFit(false)(
      canvasWidth,
      canvasHeight,
      videoWidth,
      videoHeight
    );

    staticCanvasContext.drawImage(inputVideo, offsetX, offsetY, width, height);
    const imageData = staticCanvasContext.getImageData(
      0,
      0,
      canvasWidth,
      canvasHeight
    );
    const scanResult = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert',
    });

    const ctx = outputCanvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    if (
      !scanResult ||
      (!scanResult.binaryData?.length && !scanResult.chunks?.length)
    ) {
      return;
    }
    const base64 = bytesToBase64(scanResult.binaryData);
    onQrDetected(base64);

    const {
      topLeftCorner,
      topRightCorner,
      bottomRightCorner,
      bottomLeftCorner,
    } = scanResult.location;
    ctx.beginPath();
    ctx.lineTo(topLeftCorner.x, topLeftCorner.y);
    ctx.lineTo(topRightCorner.x, topRightCorner.y);
    ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y);
    ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y);
    ctx.lineTo(topLeftCorner.x, topLeftCorner.y);

    ctx.lineWidth = 2;
    ctx.strokeStyle = '#333';
    ctx.stroke();
  }, [inputVideo, onQrDetected, outputCanvas, isVerifying]);

  // Called first time, or when isActive or isIdle changes
  useEffect(() => {
    let intervalRef: number;
    (() => {
      // return if video or canvas not available
      if (!inputVideo || !outputCanvas || !staticCanvasContext) return;
      // else, set interval for qr scan
      intervalRef = window.setInterval(() => {
        qrReaderLoop();
      }, SCAN_PERIOD_MS);
    })();
    // Perform cleanup when component unmounts
    return () => {
      intervalRef && clearInterval(intervalRef);
    };
  }, [inputVideo, outputCanvas, qrReaderLoop]);
}
