import { getVMSMetrics, getVisits } from '@datapeace/1up-frontend-shared-api';
import { useEffect, useState } from 'react';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import moment from 'moment';

async function getVisitsDataForToday(spaceId: number) {
  const dayStart = moment().startOf('day');
  const dayEnd = moment().endOf('day');
  const vmsMetrics = await getVMSMetrics(
    spaceId,
    dayEnd.valueOf(),
    dayStart.valueOf()
  );

  const { items: visits, count } = await getVisits(spaceId, {
    page: 1,
    page_size: 20,
    checkin_at__gte: dayStart.toISOString(),
    checkin_at__lte: dayEnd.toISOString(),
    visitor_status: 'inside-premise',
  });

  return {
    vmsMetrics,
    visits,
    count,
  };
}

export type VisitsDashboardData = Awaited<
  ReturnType<typeof getVisitsDataForToday>
>;

// also allowing string in spaceId to support space slug
export function useGetVisitsDataForToday(
  spaceId: number | string | null,
  onError: (err: string | null) => void
) {
  const [visitsData, setVisitsData] = useState<VisitsDashboardData | null>(
    null
  );

  useEffect(() => {
    if (!spaceId) return;

    let timeoutRef: NodeJS.Timeout | null = null;

    const fetchVisitsData = async () => {
      try {
        const data = await getVisitsDataForToday(spaceId as number);
        setVisitsData(data);
        onError(null);
      } catch (err) {
        console.error(err);
        onError((err as IErrorResponse).message);
      }

      timeoutRef = setTimeout(fetchVisitsData, 1000 * 10); // refresh every 10 seconds
    };

    fetchVisitsData();

    return () => {
      if (timeoutRef !== null) {
        clearTimeout(timeoutRef);
      }
    };
  }, [spaceId, onError]);

  return visitsData;
}
