import { parse, UrlWithStringQuery } from 'url';
import { randomString } from './cryptiles';
import {
  algorithms,
  calculateMac,
  calculatePayloadHash,
  Credentials,
} from './crypto';
import { escapeHeaderAttribute } from './hoek';
import { nowSecs } from './utils';

export interface HeaderOptions {
  /** Oz application id */
  app?: string;
  /** Payload content-type (ignored if hash provided) */
  contentType?: string;
  credentials: Credentials;
  /** Oz delegated-by application id */
  dlg?: string;
  /** Application specific data sent via the ext attribute */
  ext?: string;
  /** Pre-calculated payload hash */
  hash?: string;
  /** Time offset to sync with server time (ignored if timestamp provided) */
  localtimeOffsetMsec?: number;
  /** A pre-generated nonce */
  nonce?: string;
  /** UTF-8 encoded string for body hash generation (ignored if hash provided) */
  payload?: string;
  /** A pre-calculated timestamp in seconds */
  timestamp?: number;
}

// Generate an Authorization header for a given request

/*
    uri: 'http://example.com/resource?a=b' or object from Url.parse()
    method: HTTP verb (e.g. 'GET', 'POST')
    options: {
        // Required
        credentials: {
            id: 'dh37fgj492je',
            key: 'aoijedoaijsdlaksjdl',
            algorithm: 'sha256'                                 // 'sha1', 'sha256'
        },
        // Optional
        ext: 'application-specific',                        // Application specific data sent via the ext attribute
        timestamp: Date.now() / 1000,                       // A pre-calculated timestamp in seconds
        nonce: '2334f34f',                                  // A pre-generated nonce
        localtimeOffsetMsec: 400,                           // Time offset to sync with server time (ignored if timestamp provided)
        payload: '{"some":"payload"}',                      // UTF-8 encoded string for body hash generation (ignored if hash provided)
        contentType: 'application/json',                    // Payload content-type (ignored if hash provided)
        hash: 'U4MKKSmiVxk37JCCrAVIjV=',                    // Pre-calculated payload hash
        app: '24s23423f34dx',                               // Oz application id
        dlg: '234sz34tww3sd'                                // Oz delegated-by application id
    }
*/

export const header = function (
  uri: string | UrlWithStringQuery,
  method: string,
  options?: HeaderOptions
) {
  // Validate inputs

  if (
    !uri ||
    (typeof uri !== 'string' && typeof uri !== 'object') ||
    !method ||
    typeof method !== 'string' ||
    !options ||
    typeof options !== 'object'
  ) {
    throw new Error('Invalid argument type');
  }

  // Application time

  const timestamp = options.timestamp || nowSecs(options.localtimeOffsetMsec);

  // Validate credentials

  const credentials = options.credentials;
  if (
    !credentials ||
    !credentials.id ||
    !credentials.key ||
    !credentials.algorithm
  ) {
    throw new Error('Invalid credentials');
  }

  if (algorithms.indexOf(credentials.algorithm) === -1) {
    throw new Error('Unknown algorithm');
  }

  // Parse URI

  if (typeof uri === 'string') {
    uri = parse(uri);
  }

  // Calculate signature

  const artifacts = {
    ts: String(timestamp),
    nonce: options.nonce || randomString(6),
    method,
    resource: uri.pathname + (uri.search || ''), // Maintain trailing '?'
    host: uri.hostname || '',
    port: +(uri.port || 0) || (uri.protocol === 'http:' ? 80 : 443),
    hash: options.hash,
    ext: options.ext,
    app: options.app,
    dlg: options.dlg,
  };

  // Calculate payload hash

  if (!artifacts.hash && (options.payload || options.payload === '')) {
    artifacts.hash = calculatePayloadHash(
      options.payload,
      options.contentType || ''
    );
  }

  const mac = calculateMac('header', credentials, artifacts);

  // Construct header

  const hasExt =
    artifacts.ext !== null &&
    artifacts.ext !== undefined &&
    artifacts.ext !== ''; // Other falsey values allowed
  let header =
    'Hawk id="' +
    credentials.id +
    '", ts="' +
    artifacts.ts +
    '", nonce="' +
    artifacts.nonce +
    (artifacts.hash ? '", hash="' + artifacts.hash : '') +
    (hasExt ? '", ext="' + escapeHeaderAttribute(artifacts.ext || '') : '') +
    '", mac="' +
    mac +
    '"';

  if (artifacts.app) {
    header =
      header +
      ', app="' +
      artifacts.app +
      (artifacts.dlg ? '", dlg="' + artifacts.dlg : '') +
      '"';
  }

  return { header, artifacts };
};
