import React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import clsx from 'clsx';
import styles from './button.module.scss';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export interface ButtonProps
  extends Pick<
    AntdButtonProps,
    | 'type'
    | 'ghost'
    | 'danger'
    | 'loading'
    | 'disabled'
    | 'icon'
    | 'shape'
    | 'htmlType'
    | 'onClick'
    | 'title'
    | 'block'
  > {
  size?: SizeType | 'xLarge';
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  size,
  type,
  ghost,
  danger,
  loading,
  disabled,
  icon,
  htmlType,
  onClick,
  shape,
  title,
  block,
  className,
  style,
  children,
}) => {
  return (
    <AntdButton
      size={size !== 'xLarge' ? size : undefined}
      type={type}
      ghost={ghost}
      danger={danger}
      loading={loading}
      disabled={disabled}
      icon={icon}
      htmlType={htmlType}
      onClick={onClick}
      shape={shape}
      title={title}
      block={block}
      className={clsx(
        styles.Button,
        size === 'xLarge' && styles.ButtonXLarge,
        className
      )}
      style={style}
    >
      {children}
    </AntdButton>
  );
};

export const ButtonGroup = AntdButton.Group;
