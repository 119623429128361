/* eslint-disable @typescript-eslint/no-explicit-any */
import { parse } from 'query-string';
import { capitalize, flattenDeep, isObject, snakeCase } from 'lodash-es';

export const clearSearchParams = (history = window.history): void => {
  history.replaceState(null, '', window.location.pathname);
};

export const redirectWithReturnPath = (path: string): void => {
  window.location.replace(`${path}?returnPath=${window.location}`);
};

export const getSearchParamFromUrl = (key: string): any =>
  parse(window.location.search)[key];

function replaceParameters(path: string, parameters: any): string {
  const parameterKeys = Object.keys(parameters);
  for (const key of parameterKeys) {
    const pattern = `{{${key}}}`;
    const value = parameters[key];
    path = path.replace(pattern, value);
  }
  return path;
}

export function createPath(
  pathTemplate: readonly any[],
  parameters: any = {}
): string {
  const flattenedPath = flattenDeep(pathTemplate).map((item: string): string =>
    item.endsWith('/') ? item.slice(0, item.length - 1) : item
  );
  const joinedPath = flattenedPath.join('/') + '/';
  const parameterizedPath = replaceParameters(joinedPath, parameters);
  return parameterizedPath;
}

export const getFirstNestedMessage = (
  errorMessage: string | Record<string, any>
): string => {
  if (typeof errorMessage === 'string') {
    return errorMessage;
  }

  if (Array.isArray(errorMessage)) {
    if (typeof errorMessage[0] === 'string') return errorMessage[0];
    return getFirstNestedMessage(errorMessage[0]);
  }

  if (isObject(errorMessage)) {
    const [firstError] = Object.entries(errorMessage);

    if (typeof firstError[1] === 'string') {
      return `${firstError[0]?.startsWith('_') ? '' : `${firstError[0]}: `} ${
        firstError[1]
      }`;
    }

    // if value is array of string, error message will be 'fieldName: errorMessage'
    if (Array.isArray(firstError[1]) && typeof firstError[1][0] === 'string') {
      return `${firstError[0]?.startsWith('_') ? '' : `${firstError[0]}: `} ${
        firstError[1][0]
      }`;
    }

    return getFirstNestedMessage(firstError[1]);
  }

  try {
    return JSON.stringify(errorMessage);
  } catch (e) {
    return 'An error occurred!';
  }
};

export const getErrorMessage = (
  data: any,
  defaultMessage = 'Something went wrong.'
): string => {
  let errorMessage = defaultMessage;
  if (data.error && data.error.message) {
    if (typeof data.error.message === 'string') {
      errorMessage = data.error.message;
    } else if (
      Array.isArray(data.error.message) &&
      data.error.message.length > 0
    ) {
      [errorMessage] = data.error.message;
    } else if (isObject(data.error.message)) {
      errorMessage = data.error.message || defaultMessage;
    }
  }

  return getFirstNestedMessage(errorMessage);
};

export function asyncDelay(timeout = 1000) {
  return new Promise<void>((resolve) => {
    window.setTimeout(resolve, timeout);
  });
}

export const trimExtraText = (
  text: string,
  maxLength: number,
  trailingText = '..'
) => {
  // if text length is within maxLength, return it as it is.
  if (text.length <= maxLength) {
    return text;
  }

  // else trim it and add trailingText at end
  return text.substring(0, maxLength - trailingText.length) + trailingText;
};

/**
 * returns string containing only digits
 * '(1)-(234) 56' will be converted to '123456'
 */
export function extractDigitsFromText(text: string) {
  const digits = text.match(/\d+/g);
  return digits ? digits.join('') : '';
}

export const getInitialsFromName = (name = ''): string => {
  return name
    .split(' ')
    .map((item, index): string => {
      if (index === 0 || index === 1 || index === name.split(' ').length - 1) {
        return item[0];
      }
      return '';
    })
    .join('')
    .toUpperCase();
};

/**
 * draws a background behind the current content in a transparent canvas.
 */
export function drawBackgroundInTransparentCanvas(
  canvas: HTMLCanvasElement,
  backgroundColor: CanvasFillStrokeStyles['fillStyle'] = 'white'
) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('2d context not found for canvas!');
  }

  // store the current globalCompositeOperation
  const compositeOperation = ctx.globalCompositeOperation;

  // set to draw behind current content
  ctx.globalCompositeOperation = 'destination-over';

  // draw the background
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // reset the globalCompositeOperation to what it was
  ctx.globalCompositeOperation = compositeOperation;
}

export const dataURItoBlob = (dataURI: string): Blob => {
  const arr = dataURI.split(',');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const convertStringToSlug = (name: string): string => {
  return name.trim().toLowerCase().split(' ').join('-').trim();
};

export function camelToSnakeCase(text: string) {
  if (text.includes('_')) {
    // if contains udnerscore i.e already snakecase, return as it is
    return text;
  }

  return snakeCase(text);
}

interface User {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export const getFullNameOfUser = (user?: User, email = ''): string => {
  if (!user) {
    return email || '';
  }
  const { firstName = '', lastName = '' } = user;

  return (
    [firstName, lastName].filter(Boolean).map(capitalize).join(' ') ||
    user.email ||
    ''
  );
};

export const convertSubpathsIntoTitle = (subpath = ''): string => {
  return subpath
    .split('-')
    .map((item): string => capitalize(item))
    .join(' ');
};

export const downloadFileFromBase64URL = (
  dataURL: string,
  filename: string
): boolean => {
  const a = document.createElement('a');
  a.title = new Date().toLocaleDateString();
  a.href = dataURL;
  a.setAttribute('download', filename);
  const b = document.createEvent('MouseEvents');
  b.initEvent('click', false, true);
  a.dispatchEvent(b);
  return false;
};

export const getEmailOfUser = (user: User | undefined, email = ''): string => {
  if (!user) {
    return email || '';
  }
  return user.email || email || '';
};

export const OS_TYPES = {
  Windows: 'Windows',
  Linux: 'Linux',
  Mac: 'Mac',
};

export const getOSType = (): string | undefined => {
  const info = window.navigator.userAgent;

  if (info.indexOf(OS_TYPES.Windows) !== -1) {
    return OS_TYPES.Windows;
  }
  if (info.indexOf(OS_TYPES.Linux) !== -1) {
    return OS_TYPES.Linux;
  }
  if (info.indexOf(OS_TYPES.Mac) !== -1) {
    return OS_TYPES.Mac;
  }

  return undefined;
};
