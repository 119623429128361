import { ICountriesData, ICountryDetails } from '../typings';
import { extractDigitsFromText } from './common';
import { separateCallingCodeAndPhone } from './phone';

export const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).trim().toLowerCase());
};

/**
 * @deprecated use 'validatePhoneWithPrefix' function instead
 */
export const validatePhone = (phone: string): boolean => {
  return /\d{6}$/gm.test(phone);
};

export const becomePhoneInvalid = (phone: string): boolean => {
  return /\D+/.test(phone);
};

export const validatePassword = (password: string): boolean =>
  (password || '').trim().length > 0;

export const validatePhoneWithPrefix = (
  phoneNumber: string,
  {
    countriesByCallingCodes,
  }: {
    callingCodeChoices: ICountriesData['callingCodeChoices'];
    countriesByCallingCodes: { [key: string]: ICountryDetails };
  }
) => {
  const [prefix, phone] = separateCallingCodeAndPhone(phoneNumber);

  // return false if not in callingCodes list
  if (!countriesByCallingCodes[prefix]) return false;

  // length after removing space, dashes etc. from phone
  const phoneLength = extractDigitsFromText(phone).length;

  const { min = 4, max = 15 } =
    countriesByCallingCodes[prefix].mobileNumberNsnDigits || {};

  return phoneLength >= min && phoneLength <= max;
};
