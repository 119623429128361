import { Route, Routes } from 'react-router-dom';
import { AuthUsingDashboard } from '@datapeace/custom-web-components';
import { VisitsDashboard } from './tenon/visit-dashboard/visits-dashboard';
import { VaultAccess } from './soteria/soteria-vault-access';
import { CustomLogin } from './test/test-custom-login';
import { Flex } from '@datapeace/1up-frontend-web-ui';
import { Image } from 'antd';

function Home() {
  return (
    <Flex center centerContent centerItems>
      <Image src="/images/icon_256x256.png" alt="1UP" preview={false} />
    </Flex>
  );
}

export function AppRoutes() {
  const tenonRoute = '/pages/tenon/';
  const soteriaRoute = '/pages/soteria/';
  const testCustomLoginRoute = '/pages/test/';
  return (
    <Routes>
      <Route
        path={`${tenonRoute}visits-dashboard`}
        element={
          <AuthUsingDashboard>
            <VisitsDashboard />
          </AuthUsingDashboard>
        }
      />
      <Route path="*" element={<Home />} />

      <Route
        path={`${soteriaRoute}vault-access/:orgIdSlug`}
        element={<VaultAccess />}
      />
      <Route
        path={`${soteriaRoute}vault-access-login/:orgIdSlug`}
        element={<CustomLogin />}
      />
      <Route
        path={`${testCustomLoginRoute}custom-login/:orgIdSlug`}
        element={<CustomLogin />}
      />
    </Routes>
  );
}
