import {
  getCountriesData,
  mobileLoginForVaultAcess,
  sendOTPForVaultAcessLogin,
  validatePhoneWithPrefix,
  VaultAccessMobileOtpSendRequest,
  VaultAccessMobileSigninRequest,
} from '@datapeace/1up-frontend-shared-api';
import { ASSET_BASE_PATH } from './constant';

export const VaultAccessMobileLogin = ({
  mobile,
  otp,
  org_id_slug,
}: VaultAccessMobileSigninRequest) =>
  mobileLoginForVaultAcess({ mobile, otp, org_id_slug });

export const VaultAccessMobileOtpSend = ({
  mobile,
  org_id_slug,
}: VaultAccessMobileOtpSendRequest) =>
  sendOTPForVaultAcessLogin({ mobile, org_id_slug });

export const phoneValidator = async (phone: string) => {
  const countriesData = await getCountriesData();
  if (!phone || !validatePhoneWithPrefix(phone, countriesData)) {
    throw new Error('Please enter a valid mobile number!');
  }
};

export const getAssetUrl = (
  filePath: string,
  assetBasePath = ASSET_BASE_PATH
) => `${assetBasePath}${filePath}`;
