import * as Sentry from '@sentry/react';
import '@datapeace/antd-css';
import * as ReactDOM from 'react-dom';
import { name } from '../package.json';
import { SENTRY_DSN, ENVIRONMENT, API_BASE_URL } from './utils';
import './index.scss';
import { configure } from '@datapeace/1up-frontend-shared-api';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';

Sentry.init({
  dsn: SENTRY_DSN,
  release: `${name}: ${__VERSION__}`,
  environment: ENVIRONMENT,
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    // Random plugins/extensions
    'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'atomicFindClose', // Facebook borked
    'fb_xd_fragment', // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'failed to fetch', // Happens sometimes
    /.*Bad Gateway.*/i, // Happens sometimes
    /.*Blocked a frame with origin.*/i, // Internet connectivity issue
    /.*failed to fetch.*/i, // Tracking page has this
    /.*hostname could not.*/i,
    /.*connect to the server.*/i, // Internet connectivity issue in loading chunks
    /.*Loading .*chunk.*failed.*/i, // Internet issue
    /.*Network request failed.*/i, // offline
    /.*offline.*/gi, // iOS error caused when webapp goes in background
    /.*abort.*/i, // API timeouts
    /.*timeout.*/i,
    /.*timed out.*/i, // Client lost network connection
    /.*connection.*lost.*/i, // Tracking cancelled fetch request errors
    /.*cancelled.*/i,
  ],
});
configure({
  apiBaseURL: API_BASE_URL,
  deviceIdGenerator: async () => 'UNKONWN',
});

const rootEl = document.getElementById('root');

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </StrictMode>,
  rootEl
);

try {
  console.info(`You are running version ${__VERSION__} of the website`, {
    version: __VERSION__,
    commitHash: __COMMIT_HASH__,
    branch: __BRANCH__,
  });
} catch (error) {
  console.error(error);
}
