import Base64 from 'crypto-js/enc-base64';
import WordArray from 'crypto-js/lib-typedarrays';

// Generate a buffer of random bits
export const randomBits = function (bits: number) {
  if (!bits || bits < 0) {
    throw new Error('Invalid random bits count');
  }

  const bytes = Math.ceil(bits / 8);
  return WordArray.random(bytes);
};

// Generate a cryptographically strong pseudo-random data
export const randomString = function (size: number) {
  const buffer = randomBits((size + 1) * 6);
  const string = buffer
    .toString(Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return string.slice(0, size);
};
