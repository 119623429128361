import React, { useEffect } from 'react';
import {
  getAccessTokenFromStorage,
  getTokensFromUrl,
  redirectToLogin,
  setTokensInStorage,
} from '@datapeace/custom-web-utils';

function RedirectToLogin() {
  useEffect(() => {
    redirectToLogin();
  }, []);

  return null;
}

function clearTokensFromUrl() {
  const url = new URL(window.location.href);
  url.searchParams.delete('token');
  url.searchParams.delete('refresh-token');
  window.history.replaceState({}, '', url.toString());
}

/** @todo move side-effects to useEffect */

/** redirects to 1up dashboard for authentication if required */
export function AuthUsingDashboard({
  children,
}: {
  children: React.ReactElement;
}) {
  const { accessToken, refreshToken } = getTokensFromUrl();
  if (accessToken) {
    setTokensInStorage(accessToken, refreshToken);
    clearTokensFromUrl();
  }

  const tokenFromStorage = getAccessTokenFromStorage();

  if (!tokenFromStorage) {
    return <RedirectToLogin />;
  }

  return children;
}
