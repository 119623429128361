import {
  REFRESH_TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  getSearchParamFromUrl,
  redirectWithReturnPath,
  startAccessTokenRefreshProcess,
  storage,
} from '@datapeace/1up-frontend-shared-api';
import { ADMIN_APP_PATH } from './constant';

export const LOGIN_PATH = `${ADMIN_APP_PATH}/signin`;
export const LOGOUT_PATH = `${ADMIN_APP_PATH}/signout`;

export const getTokensFromUrl = () => ({
  accessToken: getSearchParamFromUrl('token'),
  refreshToken: getSearchParamFromUrl('refresh-token'),
});

export const redirectToLogin = () => redirectWithReturnPath(LOGIN_PATH);

export const redirectToLogout = () => redirectWithReturnPath(LOGOUT_PATH);

export function getAccessTokenFromStorage() {
  return storage.getItem(TOKEN_STORAGE_KEY);
}

export function setTokensInStorage(accessToken: string, refreshToken?: string) {
  storage.setItem(TOKEN_STORAGE_KEY, accessToken);

  if (refreshToken) {
    storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    startAccessTokenRefreshProcess().catch((err) => {
      console.error(err);
    });
  }
}
