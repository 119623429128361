import { Avatar, Empty, Spin, Table, Typography } from 'antd';
import {
  IVisit,
  getInitialsFromName,
} from '@datapeace/1up-frontend-shared-api';
import styles from './visit-dashboard.module.scss';
import clsx from 'clsx';
interface VisitsTableProps {
  visits: IVisit[] | undefined;
  totalItems: number | undefined;
}

export function VisitsTable({ visits, totalItems }: VisitsTableProps) {
  const isLoading = typeof totalItems !== 'number';

  return (
    <Table
      tableLayout="auto"
      className={clsx(styles.VisitsTable)}
      locale={{
        emptyText: isLoading ? (
          <Spin />
        ) : (
          <Empty
            description={
              <Typography.Title level={5}>No visits today !</Typography.Title>
            }
          />
        ),
      }}
      rowKey="id"
      dataSource={visits}
      scroll={{ x: 'max-content', y: '100%' }}
      columns={[
        {
          title: 'No.',
          dataIndex: 'id',
          key: 'id',
          width: 100,
          render: (item, record, index) => index + 1,
        },
        {
          key: 'name',
          title: 'Guest Name',
          width: 200,
          sorter: false,
          render(visit: IVisit) {
            return (
              <Typography.Text className={styles.VisitsTable__Name}>
                {visit?.firstName} {visit?.lastName}
              </Typography.Text>
            );
          },
        },
        {
          key: 'checkinPhotoUrl',
          title: 'Checkin Photo',
          sorter: false,
          width: 200,
          render(visit: IVisit) {
            return (
              <Avatar
                shape="square"
                size={100}
                src={visit?.checkinPhotoUrl}
                className={styles.VisitsTable__Photo}
              >
                {getInitialsFromName(visit?.firstName)}
              </Avatar>
            );
          },
        },
      ]}
      size="small"
      pagination={false}
    />
  );
}
