import clsx from 'clsx';
import React from 'react';
import styles from './flex.module.scss';

interface FlexProps {
  vertical?: boolean;
  center?: boolean;
  centerItems?: boolean;
  centerContent?: boolean;
  placeContent?: React.CSSProperties['placeContent'];
  placeItems?: React.CSSProperties['placeItems'];
  wrap?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Flex: React.FC<FlexProps> = ({
  vertical,
  center,
  centerItems,
  centerContent,
  placeContent,
  placeItems,
  wrap,
  className,
  style,
  children,
}) => {
  return (
    <div
      className={clsx(
        styles.Flex,
        vertical && styles.FlexVertical,
        (centerItems || center) && styles.FlexCenterItems,
        (centerContent || center) && styles.FlexCenterContent,
        wrap && styles.FlexWrap,
        className
      )}
      style={{ ...style, placeContent, placeItems }}
    >
      {children}
    </div>
  );
};
