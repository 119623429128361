import jwtDecode from 'jwt-decode';
import { IAuth, IAsyncStorage } from '../index';

export const TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

function hasTokenExpired(token: string) {
  const { exp } = jwtDecode<{ exp: number }>(token);
  const expiresInMS = +new Date(exp * 1000) - Date.now();
  return expiresInMS > 0; // returns false if token expired
}

class Auth implements IAuth {
  public storage: IAsyncStorage;

  public constructor(storage: IAsyncStorage) {
    this.storage = storage;
  }

  public async getAccessToken() {
    const token = await this.storage.getItem(TOKEN_STORAGE_KEY);

    // trims the text 'bearer ' from the token string, if present
    if (token && token.split(' ')?.[0] === 'bearer') {
      return token.substr(token.indexOf(' ') + 1);
    }

    return token;
  }

  public setAccessToken(token: string) {
    return this.storage.setItem(TOKEN_STORAGE_KEY, token);
  }

  public removeAccessToken() {
    return this.storage.removeItem(TOKEN_STORAGE_KEY);
  }

  public async isLoggedIn() {
    const accessToken = await this.getAccessToken();
    if (!accessToken) return false;

    const refreshToken = await this.getRefreshToken();
    if (refreshToken) {
      return hasTokenExpired(refreshToken);
    }

    return hasTokenExpired(accessToken);
  }

  // refresh token
  public async getRefreshToken() {
    return await this.storage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  public setRefreshToken(token: string) {
    return this.storage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
  }

  public removeRefreshToken() {
    return this.storage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }
}

export { Auth };
