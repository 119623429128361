import { Col, Row, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  LogoutOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { IVMSMetrics } from '@datapeace/1up-frontend-shared-api';
import { Card, Typography } from 'antd';
import styles from './visit-dashboard.module.scss';

export type VisitsStatsCardProps = {
  title: React.ReactNode;
  metric: React.ReactNode;
  isLoading: boolean;
  tooltip: React.ReactNode;
};

const colResponsiveProps = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 8,
};

export const VisitsStats = ({
  vmsMetrics,
}: {
  vmsMetrics: IVMSMetrics | undefined;
}) => {
  const isLoading = !vmsMetrics;
  const visitsStatsCardData = [
    {
      icon: CheckCircleOutlined,
      title: 'Checkins',
      tooltip: 'Number of people who have checked in.',
      value: vmsMetrics?.checkinCount || 0,
    },
    {
      icon: LogoutOutlined,
      title: 'Checkouts',
      tooltip: 'Number of people who have checked out.',
      value: vmsMetrics?.checkoutCount || 0,
    },
    {
      icon: ShopOutlined,
      title: 'Inside premise',
      tooltip:
        "Number of people who have checked in but haven't checked out yet.",
      value: vmsMetrics?.insidePremiseCount || 0,
    },
  ];

  return (
    <Row
      gutter={[8, 8]}
      className={styles.VisitStatsContainer}
      justify="center"
      align="middle"
    >
      {visitsStatsCardData?.map(({ icon: Icon, title, value, tooltip }) => {
        return (
          <Col
            {...colResponsiveProps}
            className={styles.VisitStatsContainer__Col}
          >
            <Tooltip title={tooltip}>
              <Card loading={isLoading}>
                <div className={styles.VisitStatsContainer__Content}>
                  <Icon className={styles.VisitStatsContainer__Icon} />
                  <Typography.Text className={styles.VisitStatsContainer__Text}>
                    {value}
                    <Typography.Text
                      className={styles.VisitStatsContainer__Title}
                    >
                      {title}
                    </Typography.Text>
                  </Typography.Text>
                </div>
              </Card>
            </Tooltip>
          </Col>
        );
      })}
    </Row>
  );
};
