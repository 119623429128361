/* eslint-disable @typescript-eslint/no-explicit-any */
import { instance, Credentials, header } from '../index';

export async function setDeviceCredentials(credentials: Credentials) {
  await instance.storage.setItem(
    deviceCredentialsStorageKey,
    JSON.stringify(credentials)
  );
}

export async function setDeviceIdInStorage(deviceId: number) {
  await instance.storage.setItem(deviceIdStorageKey, deviceId.toString());
}

export async function getDeviceIdFromStorage() {
  const deviceIdValue = await instance.storage.getItem(deviceIdStorageKey);
  return deviceIdValue ? +deviceIdValue : null;
}

export async function isDeviceLoggedIn() {
  const hasCredentials = !!(await getDeviceCredentials());
  const hasDeviceId = !!(await getDeviceIdFromStorage());
  return hasCredentials && hasDeviceId;
}

export const deviceCredentialsStorageKey = 'DEVICE_CREDENTIALS';
export const deviceIdStorageKey = 'DEVICE_ID';

export async function getDeviceCredentials() {
  try {
    const credentialsString = await instance.storage.getItem(
      deviceCredentialsStorageKey
    );
    if (!credentialsString) return null;

    return JSON.parse(credentialsString) as Credentials;
  } catch {
    return null;
  }
}

export async function createDeviceAuthHeader(requestOptions: {
  url: string;
  method: 'GET' | 'POST' | 'PUT';
  headers?: any;
  data?: any;
}) {
  const credentials = await getDeviceCredentials();
  if (!credentials) return '';

  let payload: string | undefined;
  let contentType: string | undefined =
    requestOptions.headers?.['Content-Type'];

  if (
    ['POST', 'PUT'].includes(requestOptions.method) &&
    (!contentType || contentType === 'application/json') &&
    requestOptions.data
  ) {
    // checking if data is json
    if (requestOptions.data.constructor === {}.constructor) {
      payload = JSON.stringify(requestOptions.data);
      contentType = 'application/json'; // in case contentType was not set
    }
  }

  const { url, method } = requestOptions;
  const { header: authHeader } = header(url, method, {
    credentials,
    payload,
    contentType,
  });

  return authHeader;
}

export async function removeDeviceCredentials() {
  await instance.storage.removeItem(deviceCredentialsStorageKey);
}

export async function removeDeviceIdFromStorage() {
  return await instance.storage.removeItem(deviceIdStorageKey);
}
