// ref: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect } from 'react';
import { useRefValue } from '.';

/**
 *
 * @param delay - pass null to stop interval
 */
export function useInterval(callback: () => void, delay: number | null = 1000) {
  // remember the latest callback
  const savedCallback = useRefValue(callback);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
    return;
  }, [delay, savedCallback]);
}
