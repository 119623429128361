/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosResponse } from 'axios';
import {
  VAULT_ACCESS_API_BASE_URL,
  VAULT_API_BASE_URL_API_KEY,
} from './constants';
import { stringify } from 'query-string';
import {
  convertToCamelCase,
  createPath,
  getErrorMessage,
  validatePhone,
} from '../utils';

export const vaultAccessApiClient = Axios.create({
  baseURL: VAULT_ACCESS_API_BASE_URL,
  paramsSerializer(params: any): any {
    return stringify(params);
  },
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': `${VAULT_API_BASE_URL_API_KEY}`,
  },
});

vaultAccessApiClient.interceptors.response.use(
  (res: any): any => {
    const data = res.data.data || res.data;
    return res.config.convertResponseToCamelCase !== false
      ? convertToCamelCase(data)
      : data;
  },
  async (error: any): Promise<any> => {
    const response = error.response || {};
    const { data = {} } = response;
    let errorMessage = 'Something went wrong. Please try again later.';
    // few APIs which do not require authentication also send 401
    // for eg. forgot-password sends 401 when email is unverified
    switch (response.status) {
      case undefined:
        if (navigator.onLine === false) {
          errorMessage = 'No internet connection!';
        }
        break;
      case 502:
      case 500:
      case 501:
        break;
      // case 401:
      //   errorMessage = getErrorMessage(data, errorMessage);
      //   accessToken = null;
      //   break;
      case 403:
        getErrorMessage(
          data,
          errorMessage || 'You do not have permission to perform this action.'
        );
        break;
      case 404:
        errorMessage = 'Requested resource not found.';
        break;
      default:
        errorMessage = getErrorMessage(data, errorMessage);
    }

    const exception = new Error(errorMessage);
    if (data && data.error && data.error.message) {
      // @ts-ignore
      exception.fields = data.error.message;
    }
    // @ts-ignore
    exception.response = response;
    return Promise.reject(exception);
  }
);
export const apiVaultAccessLoginPath = ['vault-access', 'login'] as const;

export interface VaultSigninResponse {
  orgId: string;
  spaceId: string;
  accessToken: string;
}

export interface VaultAccessMobileSigninRequest {
  mobile: string;
  otp: string;
  org_id_slug: string;
}

export interface VaultAccessMobileOtpSendRequest {
  mobile: string;
  org_id_slug: string;
}

export const sendOTPForVaultAcessLogin = async ({
  org_id_slug,
  mobile,
}: VaultAccessMobileOtpSendRequest): Promise<AxiosResponse> => {
  if (mobile && !validatePhone(mobile)) {
    throw new Error('Invalid phone number.');
  }

  const res = await vaultAccessApiClient.post<{ message: string }>(
    createPath(apiVaultAccessLoginPath),
    { mobile, org_id_slug },
    {
      params: { send_otp: true },
    }
  );
  return res.message;
};

export const mobileLoginForVaultAcess = async ({
  mobile,
  otp,
  org_id_slug,
}: VaultAccessMobileSigninRequest): Promise<VaultSigninResponse> => {
  if (!validatePhone(mobile)) {
    throw new Error('Invalid mobile number');
  }

  const res = await vaultAccessApiClient.post<VaultSigninResponse>(
    createPath(apiVaultAccessLoginPath),
    { mobile, otp, org_id_slug }
  );

  return res;
};
