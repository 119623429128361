/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { stringify } from 'query-string';
import {
  API_BASE_URL,
  apiVisitorAppAccessPath,
  apiVisitorAppConfigPath,
  apiVisitorAppSendOtpPath,
  apiVisitorAppResendOtpPath,
  apiVisitorAppVerifyOtpPath,
  apiVisitorAppPersonDetailsPath,
  apiVisitorAppVisitRequestPath,
  apiVisitorAppGetVisitRequestPath,
  apiVisitorAppPreCheckinPath,
  apiVisitorAppPreCheckoutPath,
  apiVisitorAppFaceUploadPath,
  apiVisitorAppImageUploadPath,
  apiVisitorAppPeopleSearchPath,
  apiVisitorAppPeopleExistsPath,
  apiVisitorAppSearchByFacePath,
  apiVisitorAppRegisterFacePath,
  apiVisitorBadgePath,
} from './constants';
import {
  IClientMetaData,
  IFormData,
  IOrganization,
  IPaginationParams,
  IPerson,
  IPersonForFormSearch,
  IPersonGroupFiltersForForm,
  ISpace,
  IVisit,
  IVisitBadge,
  IVisitorAppPermissions,
  IVisitRequest,
  VaccinationVerificationData,
} from '../typings';
import {
  convertToCamelCase,
  convertToSnakeCase,
  createPath,
  getErrorMessage,
} from '../utils';

let accessToken = '';
let deviceIdGenerator = async () => 'UNKNOWN';

export const visitorAppClient = Axios.create({
  baseURL: API_BASE_URL,
  paramsSerializer(params: any): any {
    return stringify(params);
  },
});

export function configureVisitorAppClient({
  siteVersion = 'UNKNOWN',
  siteId = 'default',
  deviceIdGenerator: getDeviceId,
}: {
  siteVersion: string;
  siteId: string;
  deviceIdGenerator: () => Promise<string>;
}) {
  // @ts-ignore
  visitorAppClient.defaults.headers = {
    // @ts-ignore
    'x-site-version': siteVersion,
    'x-site-id': siteId,
  };
  deviceIdGenerator = getDeviceId;
}

// axios middleware
visitorAppClient.interceptors.request.use(
  // @ts-ignore
  async (config: any): Promise<AxiosRequestConfig> & { noAuth?: boolean } => {
    const updatedConfig = config;
    try {
      if (accessToken && !updatedConfig?.noAuth) {
        updatedConfig.headers['x-access-token'] = accessToken;
      }
    } catch (error) {
      console.error({ error });
    }

    try {
      const deviceId = await deviceIdGenerator();
      if (deviceId) {
        updatedConfig.headers['x-device-id'] = deviceId;
      }
    } catch (error) {
      console.error({ error });
    }

    return { ...updatedConfig };
  },
  (error: any): Promise<AxiosError> => Promise.reject(error)
);

visitorAppClient.interceptors.response.use(
  (res: any): any => {
    const data = res.data.data || res.data;
    return res.config.convertResponseToCamelCase !== false
      ? convertToCamelCase(data)
      : data;
  },
  async (error: any): Promise<any> => {
    const response = error.response || {};
    const { data = {} } = response;
    let errorMessage = 'Something went wrong. Please try again later.';
    // few APIs which do not require authentication also send 401
    // for eg. forgot-password sends 401 when email is unverified
    switch (response.status) {
      case undefined:
        if (navigator.onLine === false) {
          errorMessage = 'No internet connection!';
        }
        break;
      case 502:
      case 500:
      case 501:
        break;
      // case 401:
      //   errorMessage = getErrorMessage(data, errorMessage);
      //   accessToken = null;
      //   break;
      case 403:
        getErrorMessage(
          data,
          errorMessage || 'You do not have permission to perform this action.'
        );
        break;
      case 404:
        errorMessage = 'Requested resource not found.';
        break;
      default:
        errorMessage = getErrorMessage(data, errorMessage);
    }

    const exception = new Error(errorMessage);
    if (data && data.error && data.error.message) {
      // @ts-ignore
      exception.fields = data.error.message;
    }
    // @ts-ignore
    exception.response = response;
    return Promise.reject(exception);
  }
);

export const getVisitorAppAccessToken = async (data: {
  space: number | string;
  deviceId: string;
  deviceLocation: {
    latitude: number;
    longitude: number;
  };
  deviceUaString: string;
  verifyToken?: string;
}): Promise<{
  accessToken: string;
  expiresIn: number;
}> => {
  const { accessToken: _accessToken, expiresIn } = await visitorAppClient.post(
    createPath(apiVisitorAppAccessPath),
    convertToSnakeCase(data),
    {
      //@ts-ignore
      noAuth: true,
    }
  );

  accessToken = _accessToken;

  return { accessToken: _accessToken, expiresIn };
};

export const getVisitorAppConfig = async () => {
  return visitorAppClient.get<{
    spaceConfig: {
      portalTitle: string;
      portalBackgroundImageUrl: string;
      portalLogoUrl: string;
      hasFrFeaturePlan: boolean;
      hasMobileFeaturePlan: boolean;
    };
    organizationConfig: {
      otpLength: number;
      countryCode: string;
      countryCallingCode: string;
    };
    organization: Pick<IOrganization, 'slug' | 'name' | 'logoUrl'> & {
      featureFlags: IVisitorAppPermissions;
    };
    space: Pick<ISpace, 'slug' | 'name'>;
  }>(createPath(apiVisitorAppConfigPath));
};

export const sendVisitorAppOtp = async (mobile: string) => {
  return visitorAppClient.post<{ otpSent: boolean }>(
    createPath(apiVisitorAppSendOtpPath),
    { mobile }
  );
};

export const resendVisitorAppOtp = async (mobile: string) => {
  return visitorAppClient.post<{ otpSent: boolean }>(
    createPath(apiVisitorAppResendOtpPath),
    { mobile }
  );
};

export const verifyVisitorAppOtp = async (otp: string, mobile: string) => {
  return visitorAppClient.post<{ otpVerified: boolean }>(
    createPath(apiVisitorAppVerifyOtpPath),
    { otp, mobile }
  );
};

export const getVisitorAppPersonDetails = async () => {
  return visitorAppClient.get<{
    matchFound: boolean;
    person: IPerson | null;
    visitDetails: {
      people: IPerson | null;
      activeVisit: IVisit | null;
      lastVisit: IVisit | null;
      isTncRequired: boolean;
      isCheckinFaceRequired: boolean;
      isCheckoutFaceRequired: boolean;
      isMobileVerified: boolean;
      isScreeningRequired: boolean;
    };
  }>(createPath(apiVisitorAppPersonDetailsPath));
};

export const createVisitorAppVisitRequest = async (
  personData:
    | {
        people: Pick<IPerson, 'firstName' | 'lastName' | 'mobileNumber'>;
        faceImageUrl?: string;
      }
    | { people: Pick<IPerson, 'firstName' | 'lastName' | 'mobileNumber'> }
) => {
  const { item } = await visitorAppClient.post<{ item: IVisitRequest }>(
    createPath(apiVisitorAppVisitRequestPath),
    convertToSnakeCase(personData)
  );
  return item;
};

export const getVisitorAppVisitRequest = async (visitRequestId: number) => {
  const { item } = await visitorAppClient.get<{ item: IVisitRequest }>(
    createPath(apiVisitorAppGetVisitRequestPath, { visitRequestId })
  );
  return item;
};

export const preCheckinInVisitorApp = async (
  visitRequestId: number,
  data: {
    checkinCustomData?: IFormData | null;
    screeningFormData?: IFormData | null;
    checkinPhotoUrl?: string;
    signatureImageData?: string;
    preCheckinMetadata: IClientMetaData | null;
    sendTncCopyTo?: string;
  } & Partial<VaccinationVerificationData>
) => {
  return await visitorAppClient.post<{
    visit: IVisit & { qrCodeUrl: string; qrCodeBase64: string };
  }>(
    createPath(apiVisitorAppPreCheckinPath, { visitRequestId }),
    convertToSnakeCase(data)
  );
};

export const preCheckoutInVisitorApp = async (
  visitRequestId: number,
  data: {
    checkoutCustomData?: IFormData | null;
    checkoutPhotoUrl?: string;
    preCheckoutMetadata: IClientMetaData | null;
  }
) => {
  return await visitorAppClient.post<{
    visit: IVisit & { qrCodeUrl: string; qrCodeBase64: string };
  }>(
    createPath(apiVisitorAppPreCheckoutPath, { visitRequestId }),
    convertToSnakeCase(data)
  );
};

export const uploadFaceInVisitorApp = async (
  visitRequestId: number,
  faceImage: Blob
) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  const fileName = `file.${faceImage.type?.split('/')?.[1] || 'jpeg'}`;

  form.append('file', faceImage, fileName);
  return await visitorAppClient.post<{ imageUrl: string }>(
    createPath(apiVisitorAppFaceUploadPath, { visitRequestId }),
    form,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

export const uploadImageInVisitorApp = async (
  visitRequestId: number,
  faceImage: Blob
) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  const fileName = `file.${faceImage.type?.split('/')?.[1] || 'jpeg'}`;

  form.append('file', faceImage, fileName);
  return await visitorAppClient.post<{ fileUrl: string }>(
    createPath(apiVisitorAppImageUploadPath, { visitRequestId }),
    form,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

export const searchPersonInVisitorApp = async (
  visitRequestId: number,
  params: IPaginationParams & Partial<IPersonGroupFiltersForForm>
) => {
  const { matchFound, persons } = await visitorAppClient.get<{
    matchFound: boolean;
    persons: Pick<
      IPersonForFormSearch,
      'id' | 'firstName' | 'lastName' | 'pictureUrl'
    >[];
  }>(createPath(apiVisitorAppPeopleSearchPath, { visitRequestId }), {
    params: convertToSnakeCase(params),
  });
  return matchFound ? persons : [];
};

export const checkIfPersonExistsInVisitorApp = async (
  visitRequestId: number,
  peopleId: number
) => {
  const { exists, person } = await visitorAppClient.get<{
    exists: boolean;
    person: Pick<IPerson, 'id' | 'firstName' | 'lastName' | 'pictureUrl'>;
  }>(createPath(apiVisitorAppPeopleExistsPath, { visitRequestId, peopleId }));

  return exists ? person : null;
};

export const searchByFaceInVisitorApp = async (faceImage: Blob) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  const fileName = `file.${faceImage.type?.split('/')?.[1] || 'jpeg'}`;

  form.append('file', faceImage, fileName);
  return await visitorAppClient.post<{
    matchFound: boolean;
    otpSent: boolean;
    fileUrl: string;
  }>(createPath(apiVisitorAppSearchByFacePath), form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const registerFaceInVisitorApp = async (faceImage: Blob) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  const fileName = `file.${faceImage.type?.split('/')?.[1] || 'jpeg'}`;

  form.append('file', faceImage, fileName);
  return await visitorAppClient.post<{
    id: string;
    faceImageUrl: string;
    isIndexed: boolean;
  }>(createPath(apiVisitorAppRegisterFacePath), form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getVisitorBadgeInVisitorApp = async (
  visitId: number,
  token: string
) => {
  const res = await visitorAppClient.get<{ visit: IVisitBadge }>(
    createPath(apiVisitorBadgePath, { visitId, token })
  );
  return res;
};
