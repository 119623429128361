import { Moment, unitOfTime } from 'moment';
import moment from 'moment/moment';
import { Duration } from 'moment';

export type numberOrDuration = number | Duration;
export type stringOrNumber = string | number;

export const convertDurationIntoMomentObjects = (
  [start, end]: [number, number],
  exact = false
): [Moment, Moment] => {
  const startTime = moment().add(start, 'days');
  const endTime = moment().add(end, 'days');
  return exact
    ? [startTime, endTime]
    : [startTime.startOf('day'), endTime.endOf('day')];
};

export const convertMomentRangeIntoString = (
  duration: [Moment, Moment],
  exact = false,
  format = 'iso'
): [stringOrNumber, stringOrNumber] => {
  let [start, end] = duration;

  if (!exact) {
    start = start.startOf('day');
    end = end.endOf('day');
  }

  return [
    format === 'iso' ? start.toISOString() : start.valueOf(),
    format === 'iso' ? end.toISOString() : end.valueOf(),
  ];
};

export const TimeDomains = {
  ALL_DAY: {
    from: moment(0).startOf('day').toISOString(),
    to: moment(0).endOf('day').toISOString(),
  },
  CUSTOM: 'CUSTOM',
};

export const if24HoursOrMore = (
  startTime: string | Moment,
  endTime: string | Moment
): boolean => Math.abs(moment(endTime).diff(startTime, 'hours')) >= 24;

export const getDateRange =
  (type: unitOfTime.StartOf): any =>
  (
    date: string
  ): {
    from: number;
    to: number;
  } => ({
    from: +moment(date).startOf(type),
    to: +moment(date).endOf(type),
  });

export const DateTypes = Object.freeze({
  DAY: {
    type: 'DAY',
    timeStep: 'hour',
    labelFormatToken: 'LT',
    getRange: getDateRange('day'),
  },
  WEEK: {
    type: 'WEEK',
    timeStep: 'day',
    labelFormatToken: 'Do',
    getRange: getDateRange('week'),
  },
  MONTH: {
    type: 'MONTH',
    timeStep: 'day',
    labelFormatToken: 'Do',
    getRange: getDateRange('month'),
  },
  YEAR: {
    type: 'YEAR',
    timeStep: 'month',
    labelFormatToken: 'MMM',
    getRange: getDateRange('year'),
  },
});

export const isFutureDate = (date: string): boolean => {
  return moment(date).startOf('day').isAfter();
};

/** e.g if isoString passed is 2020-12-31T11:59:59Z, returns date part i.e 2020-12-31 */
export function getDateFromIsoString(isoString: string) {
  return moment(isoString).format('YYYY-MM-DD');
}
