import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetVisitsDataForToday } from '@datapeace/custom-web-utils';
import { Button, Image, Result, Row, Typography } from 'antd';
import { VisitsTable } from './visits-table';
import styles from './visit-dashboard.module.scss';
import { Footer, Header } from 'antd/lib/layout/layout';
import { VisitsStats } from './visits-stats';
import moment from 'moment';
import { Flex, RedoOutlined } from '@datapeace/1up-frontend-web-ui';

export function VisitsDashboard() {
  const query = useLocation();
  const searchParams = new URLSearchParams(query.search);
  const spaceId = searchParams.get('spaceId');
  const [error, setError] = useState<string | null>(null);

  const handleError = useCallback((err: string | null) => setError(err), []);

  const visitsData = useGetVisitsDataForToday(spaceId, handleError);

  if (error) {
    return (
      <Flex center centerContent centerItems>
        <Result
          status="warning"
          title={error}
          subTitle="Please make sure correct spaceId is provided in url."
          extra={[
            <Button
              icon={<RedoOutlined />}
              type="primary"
              onClick={() => window.location.reload()}
              className={styles.Refresh}
            >
              Refresh
            </Button>,
          ]}
        />
      </Flex>
    );
  }

  return (
    <>
      <Header className={styles.Header}>
        <Row justify="center" align="middle">
          <Typography.Title level={2} className={styles.Header__Text}>
            Welcome To
          </Typography.Title>
          <Image
            className={styles.Header__Image}
            preview={false}
            src="/images/tenon-logo.png"
            alt="Tenon Groups"
          />
        </Row>
      </Header>
      <div className={styles.Content}>
        <VisitsStats vmsMetrics={visitsData?.vmsMetrics} />
        <VisitsTable
          totalItems={visitsData?.count}
          visits={visitsData?.visits}
        />
      </div>
      <Footer className={styles.Footer}>
        <Typography.Text className={styles.Footer__Text}>
          Powered by 1upvms ©{moment().year()} <br />
          {__VERSION__}
        </Typography.Text>
        <Image
          src="/images/soteria-logo.png"
          alt="Soteria"
          preview={false}
          className={styles.Footer__Image}
        />
      </Footer>
    </>
  );
}
