import { useRef } from 'react';

/**
 * pass `savedValue` into dependency and use `savedValue.current` to access value
 * savedValue.current will have the latest value
 */
export function useRefValue<T>(value: T) {
  const savedValue = useRef(value);

  // Remember the latest value.
  savedValue.current = value;

  return savedValue;
}
