import { IAsyncStorage, storage } from '../index';

const getStorageItem = async (key: string): Promise<string | null> => {
  return Promise.resolve(storage.getItem(key));
};

const setStorageItem = async (key: string, value: string): Promise<void> =>
  Promise.resolve(storage.setItem(key, value));

const removeStorageItem = (key: string): Promise<void> =>
  Promise.resolve(storage.removeItem(key));

const clearAll = (): Promise<void> => Promise.resolve(storage.clearAll());

export const asyncStorage: IAsyncStorage = {
  getItem: getStorageItem,
  setItem: setStorageItem,
  removeItem: removeStorageItem,
  clearAll,
};
